<script>
// import ClienteList from "components/suporte/components/task/List"
import {dashboardClientes} from "@/domain/gerencia/clientes/services"
import {UFakeLoader} from "uloc-vue"

export default {
  name: 'ClientesDashboard',
  mixins: [],
  components: {
    // ClienteList,
    UFakeLoader
  },
  data () {
    return {
      loadingStats: false,
      table: {
        serverData: [],
        selected: [],
        loading: false,
        filters: {
          id: null,
          busca: null,
          internalStatus: ['opened'],
          assigned: this.$uloc.auth.session.user.person
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 50
        }
      },
      stats: {
        total: 0,
        ativos: 0,
        homolog: 0,
        cancelados: 0,
        suspensos: 0,
        manutencao: 0,
        acoes: 0,
        alertaSatisfacao: 0,
        criticalAlerts: 0
      }
    }
  },
  computed: {
  },
  mounted() {
    this.load()
  },
  methods: {
    request({pagination, filter}) {
      const extraFilters = []
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.assigned && extraFilters.push(`&assigned=${this.$uloc.auth.session.user.person}`)
      extraFilters.push(`&internalStatus=${this.table.filters.internalStatus.join(',')}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'queuePosition'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    loadDashboard () {
      this.loadingStats = true
      dashboardClientes()
          .then(response => {
            this.stats = response.data.stats
            this.loadingStats = false
          })
          .catch(error => {
            this.loadingStats = false
            this.alertApiError(error)
          })
    },
    load() {
      this.loadDashboard()
      /*this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })*/
    }
  },
  meta: {
    title: 'Clientes',
    name: 'Clientes'
  }
}
</script>

<template>
  <div class="scontent sdash">
    <div class="dash-btns">
      <a class="dash-btn">
        <div class="img"><img src="../assets/images/checked.png"></div>
        <div class="label">Ativos</div>
        <div class="value" v-if="!loadingStats">{{ stats.ativos }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </a>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/doc-check.png"></div>
        <div class="label">Homologação</div>
        <div class="value" v-if="!loadingStats">{{ stats.homolog }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/folder.png"></div>
        <div class="label">Perdidos</div>
        <div class="value" v-if="!loadingStats">{{ stats.cancelados }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
      <router-link tag="a" :to="{name: 'clientes.lista', params: {status: 'maintenance'}}" class="dash-btn">
        <div class="img"><img src="../assets/images/computer-alert.png"></div>
        <div class="label">Manutenção</div>
        <div class="value red" v-if="!loadingStats">{{ stats.manutencao }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </router-link>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/cliente-list.png"></div>
        <div class="label">Ações Necessárias</div>
        <div class="value red" v-if="!loadingStats">{{ stats.acoes }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/cliente-alert.png"></div>
        <div class="label">Alertas Críticos</div>
        <div class="value red" v-if="!loadingStats">{{ stats.criticalAlerts }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
    </div>

    <div class="sbox">
      <div class="stitle cursor-pointer" @click="load">Aprovações pendentes</div>
      <div>Em breve.</div>
<!--      <cliente-list :data="table.serverData" :request="request" :options="table" :option-column="false" colorize />-->
    </div>
  </div>
</template>
